<template>
  <div class="manage_layer_01">
    <div class="layer_top">
      <h2 class="layer_top_title">상품</h2>
      <span class="btn_close" @click="$emit('close')">
        <img
          src="@/assets/img/close.png"
          style="width: 15px; height: 15px"
          alt="닫기"
        />
      </span>
    </div>
    <div class="manage_layer_box_01">
      <form>
        <table>
          <colgroup>
            <col style="width: 180px" />
            <col style="width: 260px" />
          </colgroup>
          <tr class="line_style">
            <td class="box_style_02 list_title_style">상품명</td>
            <td class="box_style_02">{{ name }}</td>
          </tr>
          <tr class="line_style">
            <td class="box_style_02 list_title_style">가격</td>
            <td class="box_style_02">{{ amount | comma }}</td>
          </tr>
          <tr class="line_style">
            <td class="box_style_02 list_title_style">상품 설명</td>
            <td class="box_style_02">{{ description }}</td>
          </tr>
          <tr class="line_style">
            <td class="box_style_02 list_title_style">운영일수</td>
            <td class="box_style_02">{{ operatingDay }}</td>
          </tr>
        </table>
        <div class="btn_center_wrapper">
          <base-button type="primary-lg" @click="$emit('close')"
            >확인</base-button
          >
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import client from "api-client";
import BaseButton from "./core/BaseButton.vue";
export default {
  components: { BaseButton },
  props: {
    id: {
      type: Number,
      default: () => 0,
    },
    getUserName: {
      type: String,
      default: () => "-",
    },
  },
  data() {
    return {
      name: "",
      amount: "",
      operatingDay: "",
      description: "",
    };
  },
  created() {
    this.fetch();
  },
  methods: {
    async fetch() {
      await client.productFindone(this.id).then(
        (response) => {
          const { data } = response;
          console.log(data);
          this.name = data.name;
          this.amount = data.amount;
          this.operatingDay = data.operatingDay;
          this.description = data.description;
        },
        (error) => {
          console.log(error);
        }
      );
    },
  },
  filters: {
    comma(val) {
      return String(val).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
  },
};
</script>
