<template>
  <div>
    <section class="cont_01">
      <form @submit="onSearch">
        <div class="searchform_cont_01">
          <span>
            <label>등록일</label>
            <date-picker
              v-model="search.startDate.value"
              :popover="{ visibility: 'click' }"
              :max-date="new Date()"
            >
              <template v-slot="{ inputValue, inputEvents }">
                <input
                  type="text"
                  :value="inputValue"
                  v-on="inputEvents"
                  class="dt_base"
                  readonly
                />
              </template>
            </date-picker>
            <span class="ml_04 mr_04">~</span>
            <date-picker
              v-model="search.endDate.value"
              :popover="{ visibility: 'click' }"
              :max-date="new Date()"
              :min-date="search.startDate.value"
            >
              <template v-slot="{ inputValue, inputEvents }">
                <input
                  type="text"
                  :value="inputValue"
                  v-on="inputEvents"
                  class="dt_base"
                  readonly
                />
              </template>
            </date-picker>
          </span>
          <span>
            <label>검색어</label>
            <span>
              <base-select :options="searchTypeOptions" v-model="search.type" />
              <input
                type="text"
                v-model="search.q"
                placeholder="검색어를 입력해 주세요."
              />
              <base-button type="primary" nativeType="submit">검색</base-button>
            </span>
          </span>
        </div>
      </form>
    </section>
    <section class="cont_02">
      <div class="total_box addnumbox">
        <div>
          <div class="srch_total pl_40">
            <span class="srch_total_title">검색 :</span>
            <span class="total_count">{{ $n(searchTotalItems) }}</span>
            <span>명</span>
          </div>
          <div class="total ml_31">
            <span class="total_title">전체 :</span>
            <span class="total_count">{{ $n(totalItems) }}</span>
            <span>건</span>
          </div>
        </div>
        <span class="pr_40">
          <base-select
            :options="search.size.option"
            v-model="search.size.value"
            @input="fetch(1)"
            placeholder="선택"
          />
        </span>
      </div>
      <div class="table_area">
        <div>
          <base-table
            :useNo="true"
            :totalItems="totalItems"
            :currentPage="currentPage"
            :pageSize="pageSize"
            :loading="contentLoading"
            :fields="fields"
            :items="items"
            @edit="onEditClick"
            @delete="onDeleteClick"
            @click="onTableClick"
            :clickedNum="clickedNum"
            @sort="onSortClick"
          />
        </div>
        <base-pagination
          :total="totalItems"
          :loading="contentLoading"
          :currentPage="currentPage"
          :pageCount="totalPages"
          :perPage="pageSize"
          @change="fetch"
        />
      </div>
      <div class="btn_right_wrapper">
        <base-button
          class="mr_30"
          type="download"
          @click.prevent.stop="
            download('advertisement?download=xls', '상품 현황', 'xls')
          "
          :disabled="btnDisabled"
          >{{ downloadText }}</base-button
        >
        <base-button type="primary" @click="create">신규등록</base-button>
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import client from "api-client";
import BaseSelect from "@/components/core/BaseSelect.vue";
import BaseTable from "@/components/core/BaseTable/BaseTable.vue";
import BaseButton from "@/components/core/BaseButton.vue";
import BasePagination from "@/components/core/BasePagination.vue";
import Dialog from "@/components/Dialog.vue";
import RegShop from "@/components/RegShop.vue";
import DetailProduct from "@/components/DetailProduct.vue";
import File from "@/common/file";
import { API_URL, SERVICE_API_URL } from "@/common/config";

export default {
  name: "OrderFestivalShop",
  components: {
    BaseSelect,
    BaseTable,
    BaseButton,
    BasePagination,
  },
  data() {
    return {
      downloadText: "엑셀 다운로드",
      btnDisabled: false,
      currentSort: "",
      clickedNum: 0,
      searchTotalItems: 0,
      fields: [
        {
          key: "name",
          width: "150px",
          label: "상품 명",
          useClick: true,
        },
        {
          key: "operatingCount",
          width: "100px",
          label: "운영개수",
          useSort: true,
          clickedNum: 0,
          // formatter: {
          //   name: "number",
          // },
        },
        {
          key: "originPrice",
          width: "100px",
          label: "원가",
          useSort: true,
          clickedNum: 0,
          formatter: {
            name: "number",
          },
        },
        {
          key: "discountRate",
          width: "100px",
          label: "할인가",
          useSort: true,
          clickedNum: 0,
          formatter: {
            name: "number",
          },
        },
        {
          key: "amount",
          width: "100px",
          label: "최종 가격",
          useSort: true,
          clickedNum: 0,
          formatter: {
            name: "number",
          },
        },
        {
          key: "description",
          width: "150px",
          label: "상품 설명",
        },
        {
          key: "operatingDay",
          width: "140px",
          label: "운영일수",
          formatter: {
            name: "number",
          },
        },
        {
          key: "registerDate",
          width: "140px",
          label: "등록 일시",
          formatter: {
            name: "date",
            type: "short",
          },
          useSort: true,
          clickedNum: 0,
        },
      ],
      items: [],
      totalItems: 0,
      totalPages: 0,
      currentPage: 1,
      pageSize: 10,
      contentLoading: true,
      searchTypeOptions: [{ name: "상품명", value: "name" }],
      search: {
        type: "",
        q: "",
        startDate: {
          value: "",
          masks: {
            input: "YYYY-MM-DD",
          },
        },
        endDate: {
          value: "",
          masks: {
            input: "YYYY-MM-DD",
          },
        },
        size: {
          event: true,
          option: [
            {
              name: "10개씩",
              value: 10,
            },
            {
              name: "20개씩",
              value: 20,
            },
            {
              name: "30개씩",
              value: 30,
            },
            {
              name: "40개씩",
              value: 40,
            },
            {
              name: "50개씩",
              value: 50,
            },
          ],
          value: 10,
        },
      },
    };
  },
  created() {
    this.$Progress.start();
    this.fetch();
  },
  mounted() {
    this.$Progress.finish();
  },
  computed: {
    ...mapGetters(["isAuthenticated"]),
  },
  methods: {
    download(url, name, type, params) {
      this.downloadText = "다운로드 중";
      this.btnDisabled = true;
      params = {
        page: this.currentPage,
        size: this.pageSize,
        download: "xls",
      };
      if (this.search.q && this.search.type) {
        params[this.search.type] = this.search.q;
      }
      url = `${API_URL}${SERVICE_API_URL}/shop/product`;
      File.download(url, name, type, params, (response) => {
        console.log(response);
        // if (response.status == 200) {
        this.btnDisabled = false;
        this.downloadText = "엑셀 다운로드";
        // }
      });
    },
    onTableClick(params) {
      this.$modal.show(
        DetailProduct,
        {
          id: params.item.id,
        },
        {
          width: 500,
          height: "auto",
        },
        {
          "before-open": () => {},
          "before-close": (response) => {
            if (response.params) {
              if (response.params.refresh) {
                this.fetch();
              }
            }
          },
        }
      );
    },
    onSearch(event) {
      event.preventDefault();
      this.fetch(1);
    },
    create() {
      this.$modal.show(
        RegShop,
        {},
        {
          clickToClose: false,
          width: 632,
          height: "auto",
        },
        {
          "before-open": () => {},
          "before-close": (response) => {
            if (response.params) {
              if (response.params.refresh) {
                // 페이지를 유지하면서 새로고침
                this.fetch();
              }
            }
          },
        }
      );
    },
    onEditClick(params) {
      this.$modal.show(
        RegShop,
        {
          id: params.item.id,
        },
        {
          clickToClose: false,
          width: 632,
          height: "auto",
        },
        {
          "before-open": () => {},
          "before-close": (response) => {
            if (response.params) {
              if (response.params.refresh) {
                // 페이지를 유지하면서 새로고침
                this.fetch();
              }
            }
          },
        }
      );
    },
    onDeleteClick(params) {
      this.$modal.show(
        Dialog,
        {
          type: "confirm",
          title: "알림",
          content: "정말 삭제하시겠습니까?",
        },
        {
          width: 400,
          height: "auto",
        },
        {
          "before-open": () => {},
          "before-close": (response) => {
            if (response.params.confirm) {
              client.productRemove(params.item.id).then(
                () => {
                  // 1 page 로 이동
                  this.fetch(1);

                  this.$modal.show(
                    Dialog,
                    {
                      title: "알림",
                      content: "정상적으로 삭제되었습니다.",
                    },
                    {
                      width: 400,
                      height: "auto",
                    },
                    {
                      "before-open": () => {},
                      "before-close": () => {},
                    }
                  );
                },
                (error) => {
                  console.log(error);
                }
              );
            }
          },
        }
      );
    },

    onSortClick(value) {
      if (value.item.key != this.currentSort) {
        this.clickedNum = 0;
        for (let i = 0; i < this.fields.length; i++) {
          this.fields[i].clickedNum = 0;
        }
      }
      this.currentSort = value.item.key;
      if (this.currentSort.includes(".")) {
        const sortString = this.currentSort.split(".");
        this.currentSort =
          sortString[0] +
          sortString[1][0].toUpperCase() +
          sortString[1].slice(1);
      }
      this.clickedNum = this.clickedNum < 2 ? this.clickedNum + 1 : 0;
      this.fields[value.index].clickedNum = this.clickedNum;
      if (!this.contentLoading) {
        this.fetch(1);
      }
    },
    async fetch(page = 1) {
      this.currentPage = page;
      this.contentLoading = true;
      let params = {
        page: this.currentPage,
        size: this.pageSize,
      };
      if (this.search.startDate.value && this.search.endDate.value) {
        params.startDate = this.$date(
          new Date(this.search.startDate.value)
        ).format("YYYY-MM-DD");
        params.endDate = this.$date(new Date(this.search.endDate.value)).format(
          "YYYY-MM-DD"
        );
      }
      if (this.currentSort) {
        if (this.clickedNum == 1) {
          params.sort = `${this.currentSort}.asc`;
        } else if (this.clickedNum == 2) {
          params.sort = `${this.currentSort}.desc`;
        }
      }

      if (this.search.q && this.search.type) {
        params[this.search.type] = this.search.q;
      } else if (0 < this.search.q.length) {
        params.important = this.search.q;
        /*
        this.$modal.show(
          Dialog,
          {
            title: "알림",
            content: "검색어 항목을 선택해 주세요.",
          },
          {
            width: 400,
            height: "auto",
          },
          {
            "before-open": () => {},
            "before-close": () => {},
          }
        );
        return;
        */
      }
      Object.entries(this.search).forEach((row) => {
        if (row[1].event) {
          if (row[1].value.length !== 0) {
            params[row[0]] = row[1].value;
          }
        }
      });

      await client.productFindAll(params).then(
        (response) => {
          const { data } = response;
          // this.items = data.items.sort((a, b) => a.amount - b.amount);
          if (
            0 < this.search.q.length ||
            (this.search.startDate.value && this.search.endDate.value)
          ) {
            this.searchTotalItems = data.totalItems;
          }
          this.items = data.items;
          this.items.forEach((item) => {
            if (item.operatingCount == 0) {
              item.operatingCount = "무제한";
            }
          });
          if (this.totalItems == 0) {
            this.totalItems = data.totalItems;
          }
          this.totalPages = data.totalPages;
          this.contentLoading = false;
        },
        () => {
          // console.log(message);
          this.items = [];
          // this.totalItems = 0;
          this.totalPages = 0;
          this.searchTotalItems = 0;
          this.contentLoading = false;
        }
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>
