<template>
  <div class="layer_04">
    <div class="layer_top">
      <h2 class="layer_top_title">상품 {{ this.id ? "수정" : "등록" }}</h2>
      <span class="btn_close" @click="$emit('close')">
        <img
          src="@/assets/img/close.png"
          style="width: 15px; height: 15px"
          alt="닫기"
        />
      </span>
    </div>
    <div class="layer_03_box">
      <form action="#">
        <table class="layer_04_table">
          <colgroup>
            <col style="width: 100px" />
            <col style="width: 500px" />
          </colgroup>
          <tr class="layer_tbl">
            <td class="layer_tbl_title pt_20 pb_15">상품 명</td>
            <td class="pt_20 pb_15">
              <input type="text" class="input_box_05" v-model="name" />
            </td>
          </tr>

          <tr class="layer_tbl">
            <td class="layer_tbl_title pt_20 pb_15">상품 설명</td>
            <td class="pt_20 pb_15">
              <input
                type="text"
                class="input_box_05 ml_0"
                v-model="description"
              />
            </td>
          </tr>
          <tr class="layer_tbl">
            <td class="layer_tbl_title pt_20 pb_15">운영일수</td>
            <td class="pt_20 pb_15">
              <input
                type="number"
                class="input_box_05"
                v-model="operatingDay"
              />
            </td>
          </tr>
          <!-- 수정 -->
          <tr class="layer_tbl">
            <td class="layer_tbl_title pt_20 pb_15">원래 가격</td>
            <td class="pt_20 pb_15">
              <input
                type="number"
                class="input_box_05 ml_0"
                v-model="originPrice"
              />
            </td>
          </tr>
          <tr class="layer_tbl">
            <td class="layer_tbl_title pt_20 pb_15">할인가</td>
            <td class="pt_20 pb_15">
              <input
                type="number"
                class="input_box_05 ml_0"
                v-model="discountRate"
              />
            </td>
          </tr>
          <tr class="layer_tbl">
            <td class="layer_tbl_title pt_20 pb_15">운영개수</td>
            <td class="pt_20 pb_15">
              <input
                type="number"
                class="input_box_05 ml_0"
                v-model="operatingCount"
              />
            </td>
          </tr>
          <!-- 수정 -->
          <tr class="layer_tbl">
            <td class="layer_tbl_title pt_20 pb_15">최종 가격</td>
            <td class="pt_20 pb_15">
              <input type="number" class="input_box_05 ml_0" v-model="amount" />
            </td>
          </tr>
        </table>
        <div class="btn_center_wrapper">
          <base-button type="primary-lg" @click="edit">{{
            this.id ? "수정" : "등록"
          }}</base-button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import client from "api-client";
import BaseButton from "@/components/core/BaseButton.vue";
import Dialog from "@/components/Dialog.vue";
export default {
  components: {
    BaseButton,
  },
  props: {
    id: Number,
  },
  data() {
    return {
      name: "",
      amount: "",
      operatingDay: "",
      description: "",
      originPrice: "",
      discountRate: "",
      operatingCount: "",
    };
  },
  created() {
    this.fetch();
  },
  methods: {
    async fetch() {
      await client.productFindone(this.id).then(
        (response) => {
          const { data } = response;
          this.name = this.id ? data.name : "";
          this.amount = this.id ? data.amount : "";
          this.operatingDay = this.id ? data.operatingDay : "";
          this.description = this.id ? data.description : "";
          this.originPrice = this.id ? data.originPrice : "";
          this.discountRate = this.id ? data.discountRate : "";
          this.operatingCount = this.id ? data.operatingCount : "";
        },
        (error) => {
          console.log(error);
        }
      );
    },
    async edit() {
      let params = {
        name: this.name,
        amount: this.amount,
        operatingDay: this.operatingDay,
        description: this.description,
        originPrice: this.originPrice,
        discountRate: this.discountRate,
        operatingCount: this.operatingCount,
      };
      if (!this.name) {
        alert("상품명을 입력해주세요");
      } else if (!this.amount) {
        alert("가격을 입력해주세요");
      } else if (!this.description) {
        alert("상품 설명을 작성해주세요");
      } else if (!this.operatingDay) {
        alert("운영일수를 입력해주세요");
      } else {
        if (this.id) {
          await client.productModify(this.id, params).then(
            (response) => {
              console.log(response);
              this.$modal.show(
                Dialog,
                {
                  title: "알림",
                  content: "정상적으로 수정되었습니다.",
                },
                {
                  width: 400,
                  height: "auto",
                },
                {
                  "before-open": () => {},
                  "before-close": () => {},
                }
              );
              this.$emit("close", { refresh: true });
            },
            (error) => {
              console.log(error);
            }
          );
        } else {
          await client.productAdd(params).then(
            (response) => {
              console.log(response);
              this.$modal.show(
                Dialog,
                {
                  title: "알림",
                  content: "정상적으로 등록되었습니다.",
                },
                {
                  width: 400,
                  height: "auto",
                },
                {
                  "before-open": () => {},
                  "before-close": () => {},
                }
              );
              this.$emit("close", { refresh: true });
            },
            (error) => {
              console.log(error);
            }
          );
        }
      }
    },
  },
};
</script>
